// import { useRouter } from 'next/router'
import HomeComp from '../src/Pages/Home/'
import MyAppInternal from '../components/MyAppInternal'
import { common_getProps } from '../components/common_getProps'

const Home = (props) => {
    // const router = useRouter()
    // const { locale } = router.query
    return (
        <>
            {/* <Header />
      <h1>Hello World!</h1>
      {t("Type here")} */}
            {/* {locale} */}
            <MyAppInternal
                {...props}
                isLanding={
                    false
                    // !prevLocationRef.current // if 'home' section is the initial ladning page
                }
            >
                <HomeComp />
            </MyAppInternal>
        </>
    )
}

export async function getServerSideProps(context) {
    return common_getProps(context)
}

export default Home
