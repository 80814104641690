import React, { useCallback, useEffect, useRef } from 'react'
import { useGate2HomeContext } from '../../Context/Gate2HomeContext'
import { keyboardLanguages, sitePath } from '../../StaticData/SiteData.js'

import DropButtonMenu from '../../Components/DropButtonMenu'
import { makeStyles } from '@material-ui/core'
import Image from 'next/image'
import { useRouter } from 'next/router'

const useStyles = makeStyles((theme) => ({
    home: { marginBottom: '150px' },
    backgroundImageContainer: {
        left: '27px',
        width: '930px',
        border: '0',
        height: '364px',
        zIndex: '-1',
        position: 'relative',
        maxWidth: '100%',
        minWidth: 'auto',
        textAlign: 'center',
        verticalAlign: 'middle',
        display: 'block',
        margin: '47px auto',
        [theme.breakpoints.down('sm')]: {
            marginLeft: '0',
            top: '51px',
            width: 'auto',
            left: '0'
        }
    },
    backgroundImage: {
        width: '100%',
        height: 'auto'
    },
    starterInputs: {
        top: '-226px',
        position: 'relative',
        color: 'white',
        fontFamily: 'Georgia',
        fontSize: '22px',
        textAlign: 'center',
        lineHeight: '22px',
        direction: ({ rtl }) => (rtl ? 'rtl' : 'ltr')
    },
    lowerText: {
        margin: '1px 0 9px 0',
        textShadow: '1px 1px 0.1px #1c3e5a'
    },
    keyboardFade: {
        top: '-219px',
        opacity: '0.4',
        zIndex: '-3',
        position: 'relative',
        // margin: 'auto 42%',
        // display: 'flex',
        right: '-129px',
        [theme.breakpoints.down('sm')]: {
            display: 'none'
        }
    },
    starterH1: {
        textShadow: '1px 1px 0.1px #1c3e5a',
        color: 'white',
        fontFamily: 'Georgia',
        fontSize: '36px',
        marginBottom: '37px',
        marginTop: '6px',
        lineHeight: '36px',
        fontStyle: 'italic',
        fontWeight: 'normal',
        // direction: ({ rtl }) => (rtl ? "rtl" : "auto"),

        [theme.breakpoints.between('xs', 'sm')]: {
            // marginTop: "158px",
            fontSize: '27px'
        },
        [theme.breakpoints.between('sm', 'md')]: {
            fontSize: '35px'
            // marginTop: "80px",
        },
        [theme.breakpoints.up('md')]: {
            fontSize: '35px'
            // marginTop: "5px",
        }
    },
    starterText: {
        height: '262px',
        marginTop: '158px',
        [theme.breakpoints.down('sm')]: {
            // height: '361px',
            height: 'auto !important',
            overflow: 'hidden',
            position: 'relative',
            marginTop: '93px'
        },
        [theme.breakpoints.between('sm', 'md')]: {
            height: 'auto !important',
            overflow: 'hidden',
            position: 'relative',
            marginTop: '200px'
        },
        [theme.breakpoints.up('md')]: {
            // fontSize: "35px",
            marginTop: '159px'
        }
    },
    starterSelector: {
        margin: '83px 60px 124px 138px',
        width: '500px',
        fontWeight: 'bold',
        [theme.breakpoints.down('md')]: {
            margin: '32px auto 30px auto'
        },
        [theme.breakpoints.up('sm')]: {
            display: 'flex',
            margin: '66px auto 100px auto'
        },
        [theme.breakpoints.down('sm')]: {
            width: '100%',
            display: 'block'
        }
    },
    starterSelect: {
        width: '350px',
        height: '43px',
        marginRight: '4px',
        fontFamily: 'Arial',
        fontWeight: 'bold',
        fontSize: '16px',
        color: '#6f6f6f',
        top: '1px',
        position: 'relative',
        backgroundColor: 'white',
        cursor: 'pointer',
        [theme.breakpoints.down('md')]: {
            width: '330px',
            marginRight: '1px',
            marginBottom: '12px'
        },
        [theme.breakpoints.down('sm')]: {
            width: '80%'
        }
    },
    starterForm: {
        display: 'inline-block',

        [theme.breakpoints.down('sm')]: {
            width: '100%'
        },
        [theme.breakpoints.down('md')]: {
            float: 'none'
        }
    },
    starterButton: {
        margin: theme.spacing(0.5),
        // ({ rtl }) => (rtl ? "rtl" : "auto"),
        direction: ({ rtl }) => (rtl ? 'rtl' : 'ltr'),
        padding: '6px 9px 6px 12px',
        fontWeight: 'bold',
        backgroundColor: '#7e9e41 !important',
        '&:hover': {
            filter: 'brightness(122%)'
        },
        wordWrap: 'normal',
        width: '130px',
        marginLeft: ({ rtl }) => (rtl ? 'auto' : '18px'),
        marginRight: ({ rtl }) => (rtl ? '18px' : 'auto'),
        fontFamily: 'Arial',
        bottom: '4px',
        height: '44px',
        [theme.breakpoints.down('sm')]: {
            width: '80%',
            marginLeft: '0 !important',
            marginRight: '0 !important'
        }
    },
    hrLongStart: {
        margin: '15px auto',
        width: '745px',
        height: '1px',
        background: '#264a6b',
        borderBottom: '1px solid #93c8f3',
        opacity: '0.4'
    }
}))

//= ========================
function Home({ isLanding, t, i18n }) {
    const classes = useStyles({ rtl: i18n.dir() == 'rtl' })

    const router = useRouter()

    const [Gate2homeState, Gate2homeDispatch] = useGate2HomeContext()

    const selectLanguageEl = useRef(null)

    useEffect(() => {
        Gate2homeDispatch({
            type: 'retreive-storage',
            payload: ['selectedLanguage', 'lastLanguage', 'lastLocale']
        })
    }, [Gate2homeDispatch])

    const handleContinue = useCallback(() => {
        if (selectLanguageEl.current.value !== 'none') {
            // SEND_EVENT: 'homepage_continue'
            router.push(sitePath.keysToPath(selectLanguageEl.current.value, '', i18n))
        } else {
            // SEND_EVENT: 'homepage_continue_while_none'
        }
        // if (selected === "no") {
        //     return;
        // }
        // localStorage.setItem("selected_language", selected)
    }, [i18n, router])

    useEffect(() => {
        if (isLanding && !window.location.href.split('/')[3]) {
            // if landing and for sure home in url
            Gate2homeDispatch({
                type: 'save-storage',
                payload: { key: 'goToKeyboard', value: true }
            })
        }
    }, [Gate2homeDispatch, isLanding])

    useEffect(() => {
        if (Gate2homeState.goToKeyboard && Gate2homeState.selectedLanguage) {
            Gate2homeDispatch({
                type: 'save-storage',
                payload: { key: 'goToKeyboard', value: false }
            })
            handleContinue()
        }
    }, [Gate2homeDispatch, Gate2homeState.goToKeyboard, Gate2homeState.selectedLanguage, handleContinue])

    // if first time here and from keyboard page, choose that keyboard
    useEffect(() => {
        if (Gate2homeState.lastLanguage && !Gate2homeState.selectedLanguage) {
            Gate2homeDispatch({
                type: 'save-storage',
                payload: {
                    key: 'selectedLanguage',
                    value: Gate2homeState.lastLanguage
                }
            })
        }
    }, [Gate2homeDispatch, Gate2homeState.lastLanguage, Gate2homeState.selectedLanguage])

    // if landing page is '/' and lastLocale exists, change to it ('/' is unique page, defaults to 'en' until other language was chosen on client)
    // useEffect(() => {
    //     if (Gate2homeState.lastLocale && i18n.language === 'en' && Gate2homeState.lastLocale !== 'en') {
    //         i18next.changeLanguage(Gate2homeState.lastLocale)
    //         Gate2homeDispatch({
    //             type: 'save-storage',
    //             payload: { key: 'lastLocale', value: '' }
    //         })
    //     }
    // }, [Gate2homeDispatch, Gate2homeState.lastLocale, i18n.language])

    const handleSelectChange = (evt) => {
        Gate2homeDispatch({
            type: 'save-storage',
            payload: { key: 'selectedLanguage', value: evt.target.value }
        })
    }

    return (
        <div className={classes.home}>
            <div className={classes.backgroundImageContainer}>
                <Image
                    src='/images/starterbgtrans.png'
                    alt='Front page big logo'
                    width='930'
                    height='364'
                    className={classes.backgroundImage}
                    priority={true}
                />
            </div>
            {/* <img
        src={StarterBgImage.src}
        className={classes.backgroundImage}
        alt="background"
        width="930"
        height="364"
      /> */}
            <div id='starter-inputs' className={classes.starterInputs} role='main form'>
                <div className={classes.starterText}>
                    <h1 className={classes.starterH1}>{t('BRAND.Type in your language, anywhere!')}</h1>
                    <div className={classes.hrLongStart} />
                    <div className={classes.starterSelector}>
                        <form onSubmit={handleContinue} className={classes.starterForm}>
                            <select
                                className={classes.starterSelect}
                                ref={selectLanguageEl}
                                onChange={handleSelectChange} // handle innerHTML change
                                onBlur={handleSelectChange}
                                value={Gate2homeState.selectedLanguage ? Gate2homeState.selectedLanguage : 'none'}
                            >
                                <option key='none' value='none'>
                                    {t('UI.Select Your Language')}:
                                </option>
                                {keyboardLanguages.allSortedLocaleNamePlusNative(i18n, false).map((obj) => (
                                    <option key={obj.key} value={obj.key}>
                                        {obj.value}
                                    </option>
                                ))}
                            </select>
                        </form>
                        <DropButtonMenu
                            text={t('UI.Continue')}
                            i18n={i18n}
                            className={classes.starterButton}
                            onClick={handleContinue}
                            dropdown={false}
                            eventKey='continue'
                        />
                    </div>
                    <div className={classes.hrLongStart} />
                    <div style={{ height: 0 }}>
                        <div className={classes.keyboardFade}>
                            <Image
                                src='/images/keyboard-fade3.png'
                                alt='keyboard fading in the background'
                                width='491'
                                height='189'
                                priority={true}
                            />
                        </div>
                    </div>
                    <div className={classes.hrLongStart} />
                    <div className={classes.lowerText}>
                        {t('BRAND.The best solution available for an on-screen virtual keyboard emulator')}
                    </div>
                    <div className={classes.hrLongStart} />
                </div>
            </div>
        </div>
    )
}

export default Home
